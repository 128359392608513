import Gallery1 from '@assets/images/irma_herwin-gallery-1.jpg';
import Gallery1_thumbnail from '@assets/images/irma_herwin-gallery-1_thumbnail.jpeg';
import Gallery2 from '@assets/images/irma_herwin-gallery-2.jpg';
import Gallery2_thumbnail from '@assets/images/irma_herwin-gallery-2_thumbnail.jpeg';
import Gallery3 from '@assets/images/irma_herwin-gallery-3.jpg';
import Gallery3_thumbnail from '@assets/images/irma_herwin-gallery-3_thumbnail.jpeg';

export const photos = [
  {
    original: Gallery1,
    thumbnail: Gallery1_thumbnail,
  },
  {
    original: Gallery2,
    thumbnail: Gallery2_thumbnail,
  },
  {
    original: Gallery3,
    thumbnail: Gallery3_thumbnail,
  },
];
