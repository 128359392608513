import { GOOGLE_MAPS } from '@/constants'; // Import GOOGLE_MAPS and GOOGLE_MAPS_TITLE from constants
import React, { Fragment } from 'react';
import { styWrapper } from './styles';

function LocationSection() {
  const renderGoogleMaps = () => {
    return (
      <Fragment>
        {GOOGLE_MAPS.locations.map((location, index) => (
          <div key={index} id={`fh5co-couple-story-${index}`} className="fh5co-section-gray" css={styWrapper}>
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 text-center fh5co-heading animate-box">
                  <h2 className="main-font">{location.title}</h2>
                  <p className="sub-title">
                    <a
                      href={location.link}
                      title="Click untuk melihat peta di Google Maps"
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: '#828282' }}
                    >
                      <strong>{location.title}</strong>
                    </a>{' '}
                    <br />
                    <div dangerouslySetInnerHTML={{ __html: location.description }} />
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-10 col-md-offset-1">
                  <iframe
                    src={location.iframe}
                    width="100%"
                    height="450"
                    frameBorder="0"
                    style={{ border: '0' }}
                    allowFullScreen
                    aria-hidden="false"
                    tabIndex="0"
                    title={GOOGLE_MAPS.title}
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Fragment>
    );
  };

  return <Fragment>{renderGoogleMaps()}</Fragment>;
}

export default React.memo(LocationSection);
