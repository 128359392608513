import { FAMILY_INFO } from '@/constants';
import React from 'react';

const InviteInfo = () => {
  return (
    <div>
      <p className="info"><b>{FAMILY_INFO.primaryInvite}</b></p>
      <p className="info">
        {FAMILY_INFO.families.map((family, index) => (
          <React.Fragment key={index}>
            {family}
            {index < FAMILY_INFO.families.length - 1 && <br />}
          </React.Fragment>
        ))}
      </p>
    </div>
  );
};

export default InviteInfo;
