import { bool } from 'prop-types';
import React, { Fragment } from 'react';

import ButtonLive from './ButtonLive';
import WeddingInfoBox from './WeddingInfoBox';

import { EVENT_INFO, SETTINGS } from '@/constants';
import { styWrapper } from './styles';

function WeddingSection({ isInvitation }) {
  const renderGuestInfo = () => {
    return (
      <Fragment>
        {
          EVENT_INFO.for === 'one' ? (
            <div className="col-md-8 col-md-offset-4">
              <WeddingInfoBox 
                title={EVENT_INFO.weddingGuestInfo[0].title}
                time={EVENT_INFO.weddingGuestInfo[0].time}
                date={EVENT_INFO.weddingGuestInfo[0].date}
                description={EVENT_INFO.weddingGuestInfo[0].description} 
              />
            </div>
          ) : (
            <div className="col-md-10 col-md-offset-1">
              {EVENT_INFO.weddingGuestInfo.map((info, index) => (
                <WeddingInfoBox 
                  key={index}
                  title={info.title}
                  time={info.time}
                  date={info.date}
                  description={info.description} 
                />
              ))}
            </div>
          )
        }
        {SETTINGS.instagramLive ? <ButtonLive /> : null}
      </Fragment>
    );
  };

  const renderInvitationInfo = () => {
    return (
      <Fragment>
        {
          EVENT_INFO.for === 'one' ? (
            <div className="col-md-8 col-md-offset-4">
              <WeddingInfoBox 
                title={EVENT_INFO.weddingInvitationInfo[0].title}
                time={EVENT_INFO.weddingInvitationInfo[0].time}
                date={EVENT_INFO.weddingInvitationInfo[0].date}
                description={EVENT_INFO.weddingInvitationInfo[0].description} 
              />
            </div>
          ) : (
            <div className="col-md-10 col-md-offset-1">
              {EVENT_INFO.weddingInvitationInfo.map((info, index) => (
                <WeddingInfoBox 
                  key={index}
                  title={info.title}
                  time={info.time}
                  date={info.date}
                  description={info.description} 
                />
              ))}
            </div>
          )
        }
      </Fragment>
    );
  };

  return (
    <Fragment>
      <div id="fh5co-event" css={styWrapper}>
        <div className="overlay" />
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
              <span className="bismillah">بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</span>
              <h2 className="main-font main-font__wedding">Akad Nikah dan Syukuran Pernikahan</h2>
              <span className="sub-title sub-title__wedding">Insya Allah akan diselenggarakan pada:</span>
            </div>
          </div>
          <div className="row">
            {!isInvitation && renderGuestInfo()}
            {isInvitation && renderInvitationInfo()}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

WeddingSection.propTypes = {
  isInvitation: bool.isRequired,
};

export default React.memo(WeddingSection);
